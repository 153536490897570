import {Button, Card, Col, Divider, Modal, Row, Space, Typography} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import {
    useGetNegotiationDetailQuery,
} from "../../redux/api/negotiationsApiSlice";
import {skipToken} from "@reduxjs/toolkit/dist/query";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {LeftOutlined, Loading3QuartersOutlined} from "@ant-design/icons";
import NegotiationDetailList from "../../components/negotiationForm/sidebars/status/NegotiationDetailList";
import NegotiationStatus from "../../components/negotiationForm/sidebars/status/NegotiationStatus";
import CoverageConfirmationSurvey from "../../components/coverageConfirmation/CoverageConfirmationSurvey";
import {SurveyContext} from "../../context/SurveyContext";
import CoverageConfirmationStatusTag from "../../components/coverageConfirmation/CoverageConfirmationStatusTag";
import SelectSurveyPageCard from "../../components/coverageConfirmation/SelectSurveyPageCard";
import {States} from "../../types";
import dayjs from "dayjs";
import SendCoverageConfirmationButton from "../../components/coverageConfirmation/SendCoverageConfirmationButton";
import CoverageConfirmationGeneralData from "../../components/coverageConfirmation/CoverageConfirmationGeneralData";
import AdditionalInsuredsCoverageConfirmation
    from "../../components/coverageConfirmation/AdditionalInsuredsCoverageConfirmation";
import ShowCoverageConfirmationJson from "../../components/coverageConfirmation/ShowCoverageConfirmationJson";
import {
    useConfirmNotSendCoverageConfirmationMutation, useConfirmSendCoverageConfirmationMutation,
    useCreateNegotiationCoverageConfirmationMutation,
    useGetNegotiationCoverageConfirmationDataQuery, useRevertDraftCoverageConfirmationMutation
} from "../../redux/api/coverageConfirmationApiSlice";

const {Title, Text} = Typography;
const {confirm} = Modal;

export enum CoverageConfirmationStates {
    DRAFT = '0000',
    READING = '0050',
    TO_SEND = '0100',
    SENT = '0200',
    ERROR_WHEN_SENT = '0300',
    ERROR_CREATION_DATA = '0400',
    NOT_TO_SEND = '0500',
}

const CoverageConfirmationPage = () => {

    const {id} = useParams();
    const navigate = useNavigate();

    const isCreationPending = useRef(false);

    const [polling, setPolling] = useState<boolean>(true);

    const {
        data: negotiation,
        isLoading: isNegotiationsDetailsLoading,
        isFetching: isNegotiationsDetailsFetching,
        refetch: refetchNegotiation,
    } = useGetNegotiationDetailQuery(id ?? skipToken)

    const {
        data: coverageConfirmationData,
        refetch: refetchCoverageConfirmationData,
        isLoading: isCoverageConfirmationDataLoading
    } = useGetNegotiationCoverageConfirmationDataQuery(negotiation?.coverageconfirmation ?? skipToken);

    const [
        createCoverageConfirmation,
        {
            isLoading: isCreatingCoverageConfirmation,
        }
    ] = useCreateNegotiationCoverageConfirmationMutation();

    const [
        confirmNotSendCoverageConfirmation,
        {
            isLoading: isConfirmNotSendCoverageConfirmation,
        }
    ] = useConfirmNotSendCoverageConfirmationMutation();

    const [
        revertToDraft,
        {
            isLoading: isRevertingToDraft,
        }
    ] = useRevertDraftCoverageConfirmationMutation();

    const [sendCoverageConfirmation, {isLoading: isSendingCoverageConfirmation}] = useConfirmSendCoverageConfirmationMutation();

    useEffect(() => {
        if (negotiation?.coverageconfirmation === null && !isCreationPending.current) {
            isCreationPending.current = true;
            createCoverageConfirmation(negotiation.uuid)
                .unwrap()
                .then(() => {
                    refetchNegotiation();
                    isCreationPending.current = false;
                });
        }
    }, [createCoverageConfirmation, negotiation?.coverageconfirmation, negotiation?.uuid, refetchNegotiation]);

    useEffect(() => {
        const pollingHandler = () => {
            if (coverageConfirmationData && [CoverageConfirmationStates.READING].includes(coverageConfirmationData.state)) {
                setPolling(true)
                refetchCoverageConfirmationData();
            }
        }
        const intervalId = setInterval(pollingHandler, 7000);
        pollingHandler();
        return () => clearInterval(intervalId);
    }, [coverageConfirmationData, refetchCoverageConfirmationData, polling]);

    useEffect(() => {
        if (
            coverageConfirmationData &&
            [
                CoverageConfirmationStates.DRAFT,
                //CoverageConfirmationStates.READING,
                CoverageConfirmationStates.TO_SEND,
                CoverageConfirmationStates.SENT,
                CoverageConfirmationStates.ERROR_WHEN_SENT,
                CoverageConfirmationStates.ERROR_CREATION_DATA,
                CoverageConfirmationStates.NOT_TO_SEND
            ].includes(coverageConfirmationData.state)
        ) {
            setPolling(false);
        }
    }, [coverageConfirmationData]);

    /**
     * Stile per l'overlay visibile.
     *
     * @type {React.CSSProperties}
     */
    const styleVisible: React.CSSProperties = useMemo(
        () => ({
            width: "calc( 100% - 8px)",
            height: "100%",
            backgroundColor: "rgba(255,255,255,0.5)",
            zIndex: 10000,
            borderRadius: 12,
            overflow: "hidden",
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            transition: "opacity 0.5s ease, visibility 0.5s ease",
            opacity: 1,
            visibility: "visible",
        }),
        [],
    );

    /**
     * Stile per l'overlay invisibile.
     *
     * @type {React.CSSProperties}
     */
    const styleInvisible: React.CSSProperties = useMemo(
        () => ({
            opacity: 0,
            visibility: "hidden",
        }),
        [],
    );

    const isSurveyDisabled = useMemo(() =>
        (polling ||
            isSendingCoverageConfirmation ||
            !(
                coverageConfirmationData?.state === CoverageConfirmationStates.DRAFT ||
                //coverageConfirmation?.state === CoverageConfirmationStates.READING ||
                //coverageConfirmation?.state === CoverageConfirmationStates.TO_SEND
                //coverageConfirmation?.state === CoverageConfirmationStates.SENT ||
                coverageConfirmationData?.state === CoverageConfirmationStates.ERROR_WHEN_SENT ||
                coverageConfirmationData?.state === CoverageConfirmationStates.ERROR_CREATION_DATA
                //coverageConfirmation?.state === CoverageConfirmationStates.NOT_TO_SEND
            )
        ), [coverageConfirmationData?.state, isSendingCoverageConfirmation, polling]
    );

    const isSendCoverageDisabled = useMemo(() => (
        (
            polling
        ) || !(
            (
                negotiation &&
                [States.Bound, States.Draft].includes(negotiation.state)
            ) && (
                coverageConfirmationData?.state === CoverageConfirmationStates.DRAFT ||
                //coverageConfirmation?.state === CoverageConfirmationStates.READING ||
                //coverageConfirmation?.state === CoverageConfirmationStates.TO_SEND
                //coverageConfirmation?.state === CoverageConfirmationStates.SENT ||
                coverageConfirmationData?.state === CoverageConfirmationStates.ERROR_WHEN_SENT ||
                coverageConfirmationData?.state === CoverageConfirmationStates.ERROR_CREATION_DATA
                //coverageConfirmation?.state === CoverageConfirmationStates.NOT_TO_SEND
            )
        )
    ), [coverageConfirmationData?.state, negotiation, polling]);

    const isDoNotSendCoverageDisabled = useMemo(() =>
        (
            polling ||
            isSendingCoverageConfirmation ||
            isConfirmNotSendCoverageConfirmation
        ) || !(
            (
                negotiation &&
                [States.Bound, States.Draft].includes(negotiation.state)
            ) && (
                coverageConfirmationData?.state === CoverageConfirmationStates.DRAFT ||
                coverageConfirmationData?.state === CoverageConfirmationStates.READING ||
                coverageConfirmationData?.state === CoverageConfirmationStates.TO_SEND
                //coverageConfirmation?.state === CoverageConfirmationStates.SENT ||
                //coverageConfirmation?.state === CoverageConfirmationStates.ERROR_WHEN_SENT ||
                //coverageConfirmation?.state === CoverageConfirmationStates.ERROR_CREATION_DATA ||
                //coverageConfirmation?.state === CoverageConfirmationStates.NOT_TO_SEND
            )

        ), [coverageConfirmationData?.state, isConfirmNotSendCoverageConfirmation, isSendingCoverageConfirmation, negotiation, polling]);

    const isRevertCoverageDisabled = useMemo(() =>
        (
            isRevertingToDraft
        ) || !(
            (
                //coverageConfirmation?.state === CoverageConfirmationStates.DRAFT ||
                coverageConfirmationData?.state === CoverageConfirmationStates.READING ||
                coverageConfirmationData?.state === CoverageConfirmationStates.TO_SEND ||
                //coverageConfirmation?.state === CoverageConfirmationStates.SENT ||
                coverageConfirmationData?.state === CoverageConfirmationStates.ERROR_WHEN_SENT ||
                coverageConfirmationData?.state === CoverageConfirmationStates.ERROR_CREATION_DATA ||
                coverageConfirmationData?.state === CoverageConfirmationStates.NOT_TO_SEND
            )
        ), [coverageConfirmationData?.state, isRevertingToDraft]);

    const isAdditionalInsuredVisible = !!(negotiation?.can_manage_certificates)

    return (
        <SurveyContext isAdditionalInsuredVisible={isAdditionalInsuredVisible}>
            <main style={{padding: "1rem 0.5rem"}}>
                <Row gutter={[8, 8]}>
                    <Col
                        xs={{span: 24, order: 1}}
                        xl={{span: 4, order: 1}}>
                        <Button
                            type={"link"}
                            icon={<LeftOutlined/>}
                            style={{paddingLeft: 0}}
                            onClick={() => navigate(`/negotiations/${id}`)}>
                            Torna alla trattativa
                        </Button>
                    </Col>
                </Row>
                <Row gutter={[8, 8]}>
                    <Col
                        xs={{span: 24, order: 3}}
                        sm={{span: 24}}
                        md={{span: 24}}
                        lg={{span: 15, order: 2}}
                        xl={{span: 16, order: 3}}>
                        <div
                            style={{...styleVisible, ...(!polling ? styleInvisible : {})}}
                            onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}>
                            <Space direction={"vertical"} align={"center"}>
                                <Loading3QuartersOutlined
                                    spin={true}
                                    style={{fontSize: 20, color: "#1677ff"}}
                                />
                                Lettura dei documenti in corso...
                            </Space>

                        </div>
                        <Card>
                            <Row style={{marginBottom: 24}} justify={"space-between"}>
                                <Col>
                                    <Space size={8}>
                                        <Title level={4}>
                                            Quotazione / Conferma di copertura
                                        </Title>
                                        {coverageConfirmationData &&
                                            <CoverageConfirmationStatusTag
                                                coverageConfirmation={coverageConfirmationData}/>}
                                    </Space>

                                </Col>
                            </Row>
                            <CoverageConfirmationGeneralData disabled={isSurveyDisabled}/>
                            <AdditionalInsuredsCoverageConfirmation disabled={isSurveyDisabled}/>
                            <CoverageConfirmationSurvey disabled={isSurveyDisabled}/>
                        </Card>
                    </Col>
                    <Col
                        xs={{span: 24, order: 2}}
                        sm={{span: 24}}
                        md={{span: 24}}
                        lg={{span: 9, order: 3}}
                        xl={{span: 8, order: 3}}>
                        <Space direction={"vertical"} size={16} style={{width: '100%'}}>
                            <SelectSurveyPageCard/>
                            {negotiation && (
                                <Card>
                                    <NegotiationStatus negotiation={negotiation}/>
                                    <Divider style={{margin: '1rem 0'}}/>
                                    <NegotiationDetailList
                                        negotiation={negotiation}
                                        isLoading={isNegotiationsDetailsFetching || isNegotiationsDetailsLoading}/>
                                    <Row justify={"center"} style={{marginTop: '1rem'}} gutter={[8, 8]}>
                                        <Col span={24}>
                                            <SendCoverageConfirmationButton
                                                type={"primary"}
                                                style={{width: '100%'}}
                                                loading={isSendingCoverageConfirmation}
                                                onClick={e => {
                                                    confirm({
                                                        title: 'Conferma invio',
                                                        centered: true,
                                                        icon: null,
                                                        content: 'Sei sicuro di voler inviare la conferma di copertura al sistema? Una volta inviata, la conferma sarà pronta per essere trasmessa a PASS con il prossimo flusso dati.',
                                                        okText: 'Conferma',
                                                        onOk: () => {
                                                            if (coverageConfirmationData?.uuid) {
                                                                sendCoverageConfirmation(coverageConfirmationData.uuid);
                                                            }
                                                        }
                                                    })
                                                }}
                                                disabled={
                                                    isSendCoverageDisabled || isSendingCoverageConfirmation
                                                }>
                                                Conferma invio
                                            </SendCoverageConfirmationButton>
                                        </Col>
                                        {(
                                            coverageConfirmationData?.datetime_send &&
                                            coverageConfirmationData?.state === CoverageConfirmationStates.SENT) && (
                                            <Col span={24}>
                                                <Text type={"secondary"}>
                                                    {`Conferma di copertura inviata il: ${dayjs(coverageConfirmationData.datetime_send).format('DD/MM/YYYY')}`}
                                                </Text>
                                            </Col>
                                        )}
                                        <Col span={12}>
                                            <Button
                                                loading={isConfirmNotSendCoverageConfirmation}
                                                style={{width: '100%'}}
                                                onClick={e => {
                                                    confirm({
                                                        title: 'Non inviare',
                                                        centered: true,
                                                        icon: null,
                                                        content: 'Sei sicuro di voler segnare la conferma di copertura come \'Da non inviare\' a PASS? Questa azione indicherà che la conferma non deve essere trasmessa a PASS. L\'azione potrà essere ripristinata.',
                                                        okText: 'Non inviare',
                                                        onOk: () => {
                                                            if (coverageConfirmationData?.uuid) {
                                                                confirmNotSendCoverageConfirmation(coverageConfirmationData.uuid);
                                                            }
                                                        }
                                                    })
                                                }}
                                                disabled={isDoNotSendCoverageDisabled}>
                                                Non inviare
                                            </Button>
                                        </Col>
                                        <Col span={12}>
                                            <Button
                                                danger={true}
                                                loading={isRevertingToDraft}
                                                style={{width: '100%'}}
                                                onClick={e => {
                                                    confirm({
                                                        title: 'Non inviare',
                                                        centered: true,
                                                        icon: null,
                                                        content: 'Sei sicuro di voler ripristinare la conferma di copertura allo stato di bozza? Questa azione è possibile solo se la conferma non è ancora stata ricevuta da PASS.',
                                                        okButtonProps: {danger: true},
                                                        okText: 'Ripristina',
                                                        onOk: () => {
                                                            if (coverageConfirmationData?.uuid) {
                                                                revertToDraft(coverageConfirmationData.uuid);
                                                            }
                                                        }
                                                    })
                                                }}
                                                disabled={isRevertCoverageDisabled}>
                                                Ripristina stato bozza
                                            </Button>
                                        </Col>
                                        {!!coverageConfirmationData?.state && ["0100", "0200", "0300"].includes(coverageConfirmationData?.state) &&
                                            <Col span={24}>
                                                <ShowCoverageConfirmationJson negotiationUuid={negotiation?.uuid}/>
                                            </Col>}
                                    </Row>
                                </Card>
                            )}
                        </Space>
                    </Col>
                </Row>
            </main>
        </SurveyContext>
    )
};

export default CoverageConfirmationPage;