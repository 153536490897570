import {useCachedCountriesQuery} from "../../redux/api/countriesApiSlice";
import {useSelector} from "react-redux";
import {selectOptions} from "../../redux/features/optionsSlice";
import {Button, Col, Divider, Form, FormProps, Input, Row, Select} from "antd";
import {AntCurrencyFormatInput} from "../inputNumber";
import React from "react";
import SelectLegalForm from "../select/SelectLegalForm";

export default function ManualAdditionalInsuredForm({formProps, submitBtnLabel = 'Conferma'}: {
    formProps?: FormProps,
    submitBtnLabel?: string
}) {

    const {data: fetchedCountries, isLoading: isLoadingCountries} = useCachedCountriesQuery()
    const countries = fetchedCountries?.map(c => ({label: c.label, value: c.code})) || []
    const provinces = useSelector(selectOptions).provinces
    const [internalForm] = Form.useForm()

    const onCountryChange = () => {
        const formIstance = formProps?.form ? formProps.form : internalForm
        const {province, country} = formIstance.getFieldsValue()
        if (country === 'IT' && province === 'EE') {
            formIstance.setFieldValue('province', null)
        }
        if (country && country !== 'IT' && province !== 'EE') {
            formIstance.setFieldValue('province', 'EE')
        }
    }

    const onProvinceChange = () => {
        const formIstance = formProps?.form ? formProps.form : internalForm
        const {province, country} = formIstance.getFieldsValue()
        if (province === 'EE' && country === 'IT') {
            formIstance.setFieldValue('country', null)
        }
        if (province && province !== 'EE' && country !== 'IT') {
            formIstance.setFieldValue('country', 'IT')
        }
    }

    return <Form
        layout="vertical"
        initialValues={{country: 'IT'}}
        form={internalForm}
        {...formProps}
    >
        <Form.Item
            label="Nome assicurato addizionale"
            name={'name'}
            rules={[
                {
                    required: true,
                    message: "Il nome dell'assicurato addizionale è obbligatorio",
                    validator: (_, value) => {
                        if (!value || value.trim() === '') {
                            return Promise.reject();
                        }
                        return Promise.resolve();
                    },
                }]}
        >
            <Input/>
        </Form.Item>
        <Form.Item
            label="Partita IVA"
            name={'vat_number'}
            rules={[
                {
                    required: true,
                    message: "La partita IVA è obbligatoria",
                    validator: (_, value) => {
                        if (!value || value.trim() === '') {
                            return Promise.reject();
                        }
                        return Promise.resolve();
                    },
                },
                {
                    max: 16,
                    message: 'La partita IVA è più lunga di 16 caratteri'
                }
            ]}
            required>
            <Input maxLength={16}/>
        </Form.Item>
        <Form.Item
            label="Forma legale"
            name={'legal_form'}
            rules={[{required: true, message: 'La forma legale è obbligatoria'}]}
        >
            <SelectLegalForm/>
        </Form.Item>
        <Row justify={'space-between'}>
            <Col xs={24} md={11}>
                <Form.Item
                    label={"Indirizzo"}
                    name={'address'}
                    rules={[
                        {
                            required: true,
                            message: "L'indirizzo è obbligatorio",
                            validator: (_, value) => {
                                if (!value || value.trim() === '') {
                                    return Promise.reject();
                                }
                                return Promise.resolve();
                            },
                        }]}
                >
                    <Input/>
                </Form.Item>
            </Col>
            <Col xs={24} md={11}>
                <Form.Item
                    label={"Civico"}
                    name={'street_number'}
                    rules={[
                        {
                            required: true,
                            message: "Il civico è obbligatorio",
                            validator: (_, value) => {
                                if (!value || value.trim() === '') {
                                    return Promise.reject();
                                }
                                return Promise.resolve();
                            },
                        }]}
                >
                    <Input/>
                </Form.Item>
            </Col>
        </Row>
        <Row justify={'space-between'}>
            <Col xs={24} md={11}>
                <Form.Item
                    label={"Città"}
                    name={'city'}
                    rules={[
                        {
                            required: true,
                            message: "La città è obbligatoria",
                            validator: (_, value) => {
                                if (!value || value.trim() === '') {
                                    return Promise.reject();
                                }
                                return Promise.resolve();
                            },
                        }]}
                >
                    <Input/>
                </Form.Item>
            </Col>
            <Col xs={24} md={11}>
                <Form.Item
                    label={"Cap"}
                    name={'postcode'}
                    rules={[
                        {
                            required: true,
                            message: "Il CAP è obbligatorio",
                            validator: (_, value) => {
                                if (!value || value.trim() === '') {
                                    return Promise.reject();
                                }
                                return Promise.resolve();
                            },
                        }]}
                >
                    <Input/>
                </Form.Item>
            </Col>
        </Row>
        <Row justify={'space-between'}>
            <Col xs={24} md={11}>
                <Form.Item
                    label={"Nazione"}
                    name={'country'}
                    rules={[{required: true, message: 'La nazione è obbligatoria'}]}
                >
                    <Select
                        optionFilterProp={'label'}
                        showSearch
                        options={countries}
                        loading={isLoadingCountries}
                        virtual={false}
                        onChange={onCountryChange}
                    />
                </Form.Item>
            </Col>
            <Col xs={24} md={11}>
                <Form.Item
                    label={"Provincia"}
                    name={'province'}
                    rules={[{required: true, message: 'La provincia è obbligatoria'}]}
                >
                    <Select
                        virtual={false}
                        options={provinces.map(el => ({value: el.value, key: el.value, label: el.text}))}
                        showSearch
                        filterOption={(input, option) => {
                            return !!(option && option.label && (option.label).toLowerCase().includes(input.toLowerCase())) || !!(option && option.value && (option.value as string).toLowerCase() === (input.toLowerCase()))
                        }}
                        onChange={onProvinceChange}
                    />
                </Form.Item>
            </Col>
        </Row>
        <Form.Item
            label={"Fatturato"}
            name={'yearly_revenues'}
        >
            <AntCurrencyFormatInput/>
        </Form.Item>
        <Form.Item
            label={'Numero dipendenti'}
            name={"number_of_employees"}
        >
            <AntCurrencyFormatInput
                suffix={''}
                decimalScale={0}
                placeholder={''}
            />
        </Form.Item>
        <Row style={{marginBottom: '1.5rem'}}><p style={{fontStyle: 'italic'}}>I campi contrassegnati da <span
            style={{color: '#ff4d4f'}}>*</span> sono obbligatori</p></Row>
        <Divider/>
        <Row justify={'space-between'}>
            <Col>
                <Form.Item>
                    <Button
                        htmlType={"submit"}
                        type={'primary'}>{submitBtnLabel}</Button>
                </Form.Item>
            </Col>
        </Row>
    </Form>
}