import {Survey as SurveyReact} from "survey-react-ui";
import {
  Question,
  QuestionCheckboxModel,
  QuestionCommentModel,
  QuestionDropdownModel,
  QuestionRadiogroupModel,
  QuestionTextModel
} from "survey-react";
import {CustomWidgetCollection, Model} from "survey-core";
import {Alert, Checkbox, DatePicker, Input, message, Modal, Radio, Select, SelectProps, Skeleton} from "antd";
import {NegotiationModel, UpdateNegotiationRequest} from "types/negotiations";
import NumberFormat from "react-number-format";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {
  useLazyCreateChecklistQuery,
  useLazyGetChecklistQuery,
  useUpdateChecklistMutation
} from "redux/api/checklistApiSlice";
import {debounce} from "lodash";
import moment from "moment";
import survey_theme from "../../../../../assets/survey_theme.json";
import {DefaultOptionType} from "antd/lib/select";

const {TextArea} = Input;

export function EurCurrencyCustomWidgetInput({question, isReadOnly}: { question: Question, isReadOnly?: boolean }) {
  return (
    <NumberFormat
      className={`ant-input ant-item-form ${isReadOnly || question.isReadOnly ? 'ant-input-disabled' : ''}`}
      style={{width: '100%'}}
      disabled={isReadOnly || question.isReadOnly}
      value={question.value}
      displayType={"input"}
      decimalSeparator={","}
      thousandSeparator={"."}
      placeholder={"0.00 €"}
      suffix={" €"}
      fixedDecimalScale={true}
      readOnly={isReadOnly || question.isReadOnly}
      decimalScale={2}
      onValueChange={(values) => {
        question.value = values.floatValue
      }}
    />
  )
}

export function NumericCustomWidgetInput({question, isReadOnly}: { question: Question, isReadOnly?: boolean }) {
  return (
    <NumberFormat
      className={`ant-input ant-item-form ${isReadOnly || question.isReadOnly ? 'ant-input-disabled' : ''}`}
      style={{width: '100%'}}
      disabled={isReadOnly || question.isReadOnly}
      value={question.value}
      displayType={"input"}
      decimalSeparator={","}
      thousandSeparator={"."}
      placeholder={"0"}
      fixedDecimalScale={true}
      readOnly={isReadOnly || question.isReadOnly}
      decimalScale={question.maskSettings?.precision ?? 2}
      onValueChange={(values) => {
        question.value = values.floatValue
      }}
    />
  )
}

export function CustomPercentageInput({question, isReadOnly}: { question: Question, isReadOnly?: boolean }) {
  return (
    <NumberFormat
      className={`ant-input ant-item-form ${isReadOnly || question.isReadOnly ? 'ant-input-disabled' : ''}`}
      disabled={isReadOnly || question.isReadOnly}
      style={{width: '100%'}}
      value={question.value}
      displayType={"input"}
      decimalSeparator={","}
      thousandSeparator={"."}
      placeholder={"0 %"}
      suffix={" %"}
      readOnly={isReadOnly || question.isReadOnly}
      onValueChange={(values) => {
        question.value = values.floatValue
      }}
    />
  )
}

export function CustomInput({question}: { question: QuestionTextModel }) {
  return (
    <Input
      style={{width: '100%'}}
      value={question.value}
      disabled={question.isReadOnly}
      onChange={(e) => question.value = e.target.value}/>
  );
}

export function CustomComment({question}: { question: QuestionCommentModel }) {
  return (
    <TextArea
      rows={question.rows}
      style={{width: '100%'}}
      value={question.value}
      disabled={question.isReadOnly}
      onChange={(e) => question.value = e.target.value}/>
  );
}

export function CustomDateInput({question, isReadOnly}: { question: Question, isReadOnly?: boolean }) {
  return (
    <DatePicker
      value={question.value ? moment(new Date(question.value)) : null}
      format={"DD/MM/YYYY"}
      style={{width: '100%'}}
      disabled={isReadOnly || question.isReadOnly}
      onChange={(value) => {
        if (value)
          question.value = value.format('YYYY-MM-DD');
        else question.value = null;
      }}/>
  )
}

export function CustomRadioGroup({question}: { question: QuestionRadiogroupModel }) {

  return (
    <>
      {question?.choices && (
        <Radio.Group
          style={{overflow: 'hidden'}}
          value={question.value}
          disabled={question.isReadOnly}
          onChange={(e) => {
            question.value = e.target.value;
          }}>
          {question.choices.map((option: any, index: number) =>
            <Radio
              key={index}
              value={option.value}>
              {option.text}
            </Radio>
          )}
        </Radio.Group>
      )}
    </>
  );
}


export function CustomCheckboxGroup({question}: { question: QuestionCheckboxModel }) {

  return (
    <>
      {question?.choices && (
        <Checkbox.Group
          style={{minWidth: 220}}
          disabled={question.isReadOnly}
          value={question.value}
          options={question.choices.map((choice: any) => ({
            label: choice.text,
            value: choice.value,
            disabled: choice.disabled
          }))}
          onChange={(e) => {
            question.value = e;
          }}
        />
      )}
    </>
  );
}

export function CustomSelectInput({question}: { question: QuestionDropdownModel }) {

  const commonSelectProps: SelectProps =
    {
      style: {width: '100%'},
      virtual: true,
      defaultValue: question.value,
      disabled: question.isReadOnly,
      dropdownMatchSelectWidth: false,
      showSearch: true,
      filterOption: (input, option) => ('' + (option?.label ?? '')).toLowerCase().includes(input.toLowerCase()),
      onChange: value => {
        question.value = value
      },
    }

  const options: DefaultOptionType[] = useMemo(() => {
    // @ts-ignore
    if (question?.choicesFromUrl) {
      // @ts-ignore
      return question?.choicesFromUrl?.map(choice => ({
        label: choice.text,
        value: choice.value
      }))
    }
    if (question.visibleChoices) {
      return question.visibleChoices.map((options: any) => ({
        label: options.text,
        value: options.value
      }))
    }
    return [];
    // @ts-ignore
  }, [question?.choicesFromUrl, question.visibleChoices]);

  return (
    <>
      {options &&
        <Select
          key={`${question.name}_${question.value}`}
          {...commonSelectProps}
          options={options}/>
      }
    </>
  )
}


//Checklist assuntiva
export function ChecklistFormDialog({
                                      isOpen,
                                      onClose,
                                      negotiation,
                                      updateNegotiation,
                                    }: {
  isOpen: boolean,
  onClose: (isNegotiationToUpdate: boolean) => void,
  negotiation: NegotiationModel,
  updateNegotiation: (data: Partial<UpdateNegotiationRequest>) => void
}) {

  const [survey, setSurvey] = useState(new Model())
  const [updateChecklist] = useUpdateChecklistMutation()
  const debounceUpdate = useCallback(debounce(({
                                                 negotiation,
                                                 checklist,
                                                 data,
                                                 visible_questions,
                                               }: {
    negotiation: string,
    checklist: string,
    data: object,
    visible_questions: { [key: string]: boolean }
  }) => updateChecklist({
    negotiation,
    checklist,
    data,
    visible_questions
  }), 500), [])
  const debounceMessage = useCallback(debounce((success: boolean) => success ? message.success('Checklist aggiornata') : message.error('Checklist non aggiornata'), 500), [])
  const [getChecklist, {
    data: getChecklistData,
    isLoading: getChecklistIsLoading,
    isFetching: getChecklistIsFetching
  }] = useLazyGetChecklistQuery()

  const [createChecklist, {
    data: createChecklistData,
    isLoading: createChecklistIsLoading,
    isFetching: createChecklistIsFetching,
  }] = useLazyCreateChecklistQuery()

  const [isChecklistCreated, setIsChecklistCreated] = useState(false)

  useEffect(() => {
    // getData
    if (isOpen) {
      const assuChecklistExist = negotiation.checklist_answers.find(c => c.checklist_type === 'ASSU')
      if (assuChecklistExist) {
        getChecklist({negotiation: negotiation.uuid, checklist: assuChecklistExist.uuid})
      } else {
        createChecklist({negotiation: negotiation.uuid, checklist_type: 'ASSU'})
          .unwrap()
          .then(res => {
            setIsChecklistCreated(true)
          })
          .catch()
      }
    }
  }, [createChecklist, getChecklist, isOpen, negotiation.checklist_answers, negotiation.uuid, updateNegotiation])

  useEffect(() => {
    // set jsonSchema
    const surveyData = getChecklistData || createChecklistData;

    if (surveyData?.jsonSchema && surveyData?.data) {
      const newSurvey = new Model(surveyData?.jsonSchema)
      newSurvey.data = surveyData.data
      if (isChecklistCreated) {
        // when the checklist is created defaultValues are passed
        const questions = newSurvey.getAllQuestions().reduce((prev, q) => ({
          ...prev,
          [q.name]: q.isVisible
        }), {})
        updateChecklist({
          negotiation: negotiation.uuid,
          checklist: surveyData.uuid,
          data: newSurvey.data,
          visible_questions: questions
        })
      }

      newSurvey.onValueChanged.add((sender) => {
        let results = newSurvey.data
        const questions = sender.getAllQuestions().reduce((prev, q) => ({...prev, [q.name]: q.isVisible}), {})
        surveyData?.jsonSchema.pages[0].elements.forEach((el) => {
          if (!(el.name in newSurvey.data)) {
            results[el.name] = null
          }
        })
        debounceUpdate({
          negotiation: negotiation.uuid,
          checklist: surveyData.uuid,
          data: results,
          visible_questions: questions
        })
          ?.unwrap()
          .then(() => debounceMessage(true))
          .catch(() => debounceMessage(false))

      });
      newSurvey.focusFirstQuestionAutomatic = false;
      newSurvey.applyTheme({cssVariables: survey_theme.cssVariables, isPanelless: survey_theme.isPanelless});
      newSurvey.setCss(
        {
          container: "ant-form ant-form-vertical",
          question: {
            header: "ant-col ant-form-item-label ant-form-item-required",
            title: "ant-form ant-form-item-label question-title",
            content: "ant-col ant-form-item-control",
            description: "ant-form-item-explain",
            hasError: "ant-form-item-explain-error",
          },
          text: {
            root: "ant-input",
          },
          comment: {
            root: "ant-input ant-input-textarea ant-input-textarea-in-form-item",
          },
        }
      )
      setSurvey(newSurvey)
    }
  }, [createChecklistData, debounceMessage, debounceUpdate, getChecklistData, isChecklistCreated, negotiation.uuid, updateChecklist])

  useEffect(() => {
    // add custom widget
    CustomWidgetCollection.Instance.addCustomWidget({
      name: 'customnumeric',
      title: 'custom Numeric',
      render: (question: Question) => {
        return <EurCurrencyCustomWidgetInput question={question}/>
      },
      isFit: (question: Question) => {
        return question.name.charAt(0) === '$';
      },
    }, "customnumeric")
    CustomWidgetCollection.Instance.addCustomWidget({
      name: 'custompercentage',
      title: 'custom Percentage',
      render: (question: Question) => {
        return <CustomPercentageInput question={question}/>
      },
      isFit: (question: Question) => {
        return question.name.charAt(0) === '%';
      },
    }, "custompercentage")
    CustomWidgetCollection.Instance.addCustomWidget({
      name: 'customRadioGroup',
      title: 'custom radio group',
      render: (question: Question) => <CustomRadioGroup question={question as QuestionRadiogroupModel}/>,
      isFit: (question: Question) => question.getType() === 'radiogroup',
    }, "customRadioGroup")
  }, [])

  return (
    <div className='checklist-modal'>
      <Modal
        title="Checklist"
        centered
        open={isOpen}
        onOk={() => {
          onClose(isChecklistCreated)
          setIsChecklistCreated(false)
        }}
        onCancel={() => {
          onClose(isChecklistCreated)
          setIsChecklistCreated(false)
        }}
        footer={null}
        destroyOnClose={true}
      >
        <Alert
          style={{marginBottom: '1rem'}}
          message={"I campi disabilitati recuperano automaticamente i dati dal form della trattativa. La modifica di quei campi deve avvenire nel form."}
          type={"info"} showIcon/>
        {!(getChecklistIsLoading || getChecklistIsFetching || createChecklistIsLoading || createChecklistIsFetching) &&
          <SurveyReact model={survey}/>}
        {(getChecklistIsLoading || getChecklistIsFetching || createChecklistIsLoading || createChecklistIsFetching) &&
          <Skeleton style={{height: '100vh'}} paragraph={{rows: 40}}/>}
      </Modal>
    </div>
  )
}